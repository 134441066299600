<template>
  <div class="text-white text-center max-w-md">
    <div class="nn-signup-carousel-img-wrapper">
      <slot name="img"></slot>
    </div>
    <h2 class="text-xl font-medium mt-6">
      <slot name="title"></slot>
    </h2>
    <p class="mt-4">
      <slot name="caption"></slot>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.nn-signup-carousel-img-wrapper {
  img {
    max-width: 363px;
    max-height: 397px;
    width: auto;
    height: auto;
    margin: auto;
  }
}
</style>
